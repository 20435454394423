import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar2 from "../../components/layout/header/Navbar2";
import Layout from "../../components/layout/Layout";
import Promo from "../../components/promo/Promo";
import Team from "../../components/team/Team";
import HeroSeven from "./HeroSeven";
import PromoAgenteInmobiliario from "../../components/promo/PromoAgenteInmobiliario";
import PromoAgenteInmobiliario2 from "../../components/promo/PromoAgenteInmobiliario2";
import Price2 from "../../components/prices/Price2";

export default function HomeSeven() {
  return (
    <Layout>
      <Navbar2 darkBg />
      <HeroSeven />
      <Promo />
      <PromoAgenteInmobiliario></PromoAgenteInmobiliario>
      <Price2></Price2>
      {/* Agregar los testimonios cuando lo tenga listo */}
      {/* <Testimonios hasBg></Testimonios> */}
      <PromoAgenteInmobiliario2></PromoAgenteInmobiliario2>
      <Team />
      <Footer space />
    </Layout>
  );
}
